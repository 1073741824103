
/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import React from 'react'
import {
  Col,
  Container,
  Row,
  Tab
} from 'react-bootstrap'
import { Accordion, Tabs, TopContent } from 'components'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ListParticipant from './ListParticipant'
import ParticipantTransferList from './ParticipantTransferList'
import ParticipantCard from './ParticipantCard'

import { 
  getHistoryParticipatAction,
  getStatusParticipantAction,
  getStatusParticipantEcardAction,
  getHistoryPaginationAction,
  resetAction
} from 'store/actions/employer/attendeeActions'
import {
  getFaqDataActions
}from 'store/actions/faqActions'

// Styles
import styles from './style.module.scss'


export const Attendee = ({}) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const descPageText = useSelector(state => state.descriptionPage)
  const lang = useSelector(state => state.language.lang)
  const [ historyData, setHistoryData ] = React.useState(null)
  const [ paginationConfig, setPaginationConfig ] = React.useState(null)
  const [ listStatus, setListStatus ] = React.useState([])
  const [ listStatusEcard, setListStatusEcard ] = React.useState([])
  const [ loadingHistory, setLoadingHistory ] = React.useState(false)
  const [ refreshHistory, setRefreshHistory ] = React.useState(0)
  const [ riwayatId, setRiwayatId ] = React.useState(1)
  const [ faq, setFaq ] = React.useState(null)
  const [ tabValue, setTabValue ] = React.useState('attendee')

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5 pb-5')}>{content}</div>
  )

  const getHistory = React.useCallback(async(id) => {
    setLoadingHistory(true)
    await dispatch(getHistoryParticipatAction(id))
        .then(
            res => {
                if(res.success){
                    setLoadingHistory(false)
                    setHistoryData(res.data.data)
                    setPaginationConfig({
                      page: res.data.page,
                      pageCount: res.data.pageCount
                    })
                }
            }
        )
  },[setLoadingHistory, setHistoryData, setPaginationConfig])

  const getStatus = React.useCallback(async() => {
    await dispatch(getStatusParticipantAction())
        .then(
            res => {
                if(res.success){
                    setListStatus(res.data.map(it => {
                        return {
                            key: it, 
                            value: it
                        }
                    }))
                }
            }
        )
  },[setListStatus])

  const getStatusEcard = React.useCallback(async() => {
    await dispatch(getStatusParticipantEcardAction())
        .then(
            res => {
                if(res.success){
                    setListStatusEcard(res.data.map(it => {
                        return {
                            key: it, 
                            value: it
                        }
                    }))
                }
            }
        )
  },[setListStatusEcard])

  const getFaq = React.useCallback(async(group) => {
    await dispatch(getFaqDataActions(group))
      .then(
        res => {
          setFaq(res.data)
        }
      )
  },[setFaq])

  const tabHandler = React.useCallback((e) => {
    setPaginationConfig(null)
    switch(e){
      case 'attendee':
        getHistory(1)
        setTabValue(e)
        setRiwayatId(1)
        break
      case 'attendee_displacement':
        getHistory(2)
        setTabValue(e)
        setRiwayatId(2)
        break
      case 'ppukp':
        break
    }
  },[setTabValue, setRiwayatId, setPaginationConfig])

  const paginationHandler = React.useCallback(async(e) => {
    setLoadingHistory(true)
    switch(tabValue){
      case 'attendee':
        setPaginationConfig(prevState => ({
          ...prevState,
          page: e
        }))
        await dispatch(getHistoryPaginationAction({
          id: riwayatId,
          page: e
        }))
            .then(
                res => {
                    if(res.success){
                        setLoadingHistory(false)
                        setHistoryData(res.data.data)
                        setPaginationConfig({
                          page: res.data.page,
                          pageCount: res.data.pageCount
                        })
                    }
                }
            )
        break
      case 'attendee_displacement':
        setPaginationConfig(prevState => ({
          ...prevState,
          page: e
        }))
        await dispatch(getHistoryPaginationAction({
          id: riwayatId,
          page: e
        }))
            .then(
                res => {
                    if(res.success){
                        setLoadingHistory(false)
                        setHistoryData(res.data.data)
                        setPaginationConfig({
                          page: res.data.page,
                          pageCount: res.data.pageCount
                        })
                    }
                }
            )
        break
    }
  },[setLoadingHistory, setHistoryData, riwayatId, setPaginationConfig, tabValue])

  React.useEffect(() => {
    getStatus()
    return () => {
      dispatch(resetAction())
    }
  },[])

  React.useEffect(() => {
    getStatusEcard()
    return () => {
      dispatch(resetAction())
    }
  },[])

  React.useEffect(() => {
    getHistory(riwayatId)
  },[refreshHistory])

  React.useEffect(() => {
    getHistory(riwayatId)
  },[riwayatId])

  React.useEffect(() => {
    getFaq('peserta')
  },[lang])
  
  return (
    <Container as="section" fluid>
      <TopContent
        text={t('employerAttende.pageTitle.text1')}
        textItalic={t('employerAttende.pageTitle.text2')}
      />
      <Row className={clsx('pb-5 mb-md-3')}>
        <Col md={6}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
            { descPageText.text }
          </p>
        </Col>
        <Col md={6}>
        </Col>
      </Row>
      <Tabs id="attendee" onSelect={tabHandler} className={clsx(styles.tabs)} responsiveMode="dropdown">
        <Tab eventKey="attendee" title={t('employerAttende.tab.list_participant')}>
          <ListParticipant
            historyData={historyData}
            listStatus={listStatus}
            loadingHistory={loadingHistory}
            setRefreshHistory={setRefreshHistory}
            paginationConfig={paginationConfig}
            paginationHandler={paginationHandler}
          />
        </Tab>
        <Tab eventKey="attendee_displacement" title={t('employerAttende.tab.participant_trans')}>
          <ParticipantTransferList
            historyData={historyData}
            loadingHistory={loadingHistory}
            setRefreshHistory={setRefreshHistory}
            paginationConfig={paginationConfig}
            paginationHandler={paginationHandler}
          />
        </Tab>
        <Tab eventKey="ppukp" title={t('employerAttende.tab.paticipant_card')}>
          <ParticipantCard
            historyData={historyData}
            listStatusEcard={listStatusEcard}
          />
        </Tab>
      </Tabs>
      {faq && faq.length > 0 && (
        <Row className="mt-5">
          <Col md={12}>
            <h1 className="font-weight-light">{t('faq.title')}</h1>
          </Col>
        </Row>
      )}
      {faq && responsiveRowRender(<Accordion itemsPerRow={faq.length} items={faq} />)}
      {/* <div className={clsx('my-4', 'pt-2 pb-3')}>
        <Billboard.Contact />
      </div> */}
    </Container>
  )
}

export default Attendee
