import axios from "utils/axios";

import {
    LOADING_EMPYR_ATTENDEE,
    LOADING_EMPYR_ATTENDEE_FALSE,
    RESET_EMPYR_ATTENDEE,
    PAGINATION_EMPYR_ATTENDEE,
} from "../../reducers/employer/attendeeReducer";
import { SET_AUTH_DETECTOR_FALSE } from "../../reducers/authDetectorReducer";
import {
    API_EMPR_DAFTAR_PESERTA,
    API_EMPR_PERPINDAHAN_PESERTA,
    API_EMPR_PESERTA_ECARD,
    API_EMPR_PESERTA_STATUS,
    API_EMPR_PESERTA_STATUS_ECARD,
    API_EMPR_REPORT,
    API_EMPR_IMAGE_ECARD,
} from "utils/api.constant";

export const postListParticipantAction = (data) => (dispatch) => {
    dispatch({
        type: LOADING_EMPYR_ATTENDEE,
    });
    return new Promise(async (resolve) => {
        await axios
            .post(API_EMPR_DAFTAR_PESERTA, data)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    dispatch({
                        type: LOADING_EMPYR_ATTENDEE_FALSE,
                    });
                    resolve({
                        success: true,
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }

                dispatch({
                    type: LOADING_EMPYR_ATTENDEE_FALSE,
                });
                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const getStatusParticipantAction = () => (dispatch) => {
    return new Promise(async (resolve) => {
        await axios
            .get(API_EMPR_PESERTA_STATUS)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    });
};

//status for eCard
export const getStatusParticipantEcardAction = () => (dispatch) => {
    return new Promise(async (resolve) => {
        await axios
            .get(API_EMPR_PESERTA_STATUS_ECARD)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    });
  };  

export const getHistoryParticipatAction = (id) => (dispatch) => {
    return new Promise(async (resolve) => {
        await axios
            .get(`${API_EMPR_REPORT}?page=1&take=3&order_by=date&type_id=${id}`)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: {
                            data: res.data.data,
                            page: res.data.meta.page,
                            pageCount: res.data.meta.pageCount,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }

                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const getHistoryPaginationAction = (data) => (dispatch) => {
    const { id, page } = data;
    return new Promise(async (resolve) => {
        await axios
            .get(`${API_EMPR_REPORT}?page=${page}&take=3&type_id=${id}`)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: {
                            data: res.data.data,
                            page: res.data.meta.page,
                            pageCount: res.data.meta.pageCount,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }

                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const postParticipantTransferAction = (data) => (dispatch) => {
    dispatch({
        type: LOADING_EMPYR_ATTENDEE,
    });
    return new Promise(async (resolve) => {
        await axios
            .post(API_EMPR_PERPINDAHAN_PESERTA, data)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    dispatch({
                        type: LOADING_EMPYR_ATTENDEE_FALSE,
                    });
                    resolve({
                        success: true,
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }

                dispatch({
                    type: LOADING_EMPYR_ATTENDEE_FALSE,
                });
                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const postHistoryEcardAction = (data) => (dispatch) => {
    dispatch({
        type: LOADING_EMPYR_ATTENDEE,
    });
    return new Promise(async (resolve) => {
        await axios
            .post(API_EMPR_PESERTA_ECARD, data)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    dispatch({
                        type: LOADING_EMPYR_ATTENDEE_FALSE,
                    });
                    resolve({
                        success: true,
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }

                dispatch({
                    type: LOADING_EMPYR_ATTENDEE_FALSE,
                });
                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const getImageCardAction = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .get(API_EMPR_IMAGE_ECARD, {
                responseType: "blob",
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err.response);
            });
    });
};

export const resetAction = () => (dispatch) => {
    dispatch({
        type: RESET_EMPYR_ATTENDEE,
    });
};
