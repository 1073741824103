import React from 'react'
import {
    Col,
    Row,
    Card,
    OverlayTrigger,
    Popover,
    Form as FormBootstrap
} from 'react-bootstrap'
import { Form, Button, ECard } from 'components'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { has } from 'lodash'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'

import styles from './style.module.scss'

import {
    postHistoryEcardAction,
    getImageCardAction
} from 'store/actions/employer/attendeeActions'

const ParticipantCard = (props) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { register, errors, setValue, handleSubmit } = useForm()
    const dataState = useSelector(state => state.employerAttendee)

    const [eCardVisible, setECardVisible] = React.useState(false)
    const [ecardImgPath, setEcardImgPath] = React.useState(null)
    const chooseParticipant = [
        // {
        //     key: 'SEMUA_PESERTA',
        //     value: t('employerAttende.tab.filter.chooseParticipant.list.all_p')
        // },
        {
            key: 'PER_PESERTA',
            value: t('employerAttende.tab.filter.chooseParticipant.list.per_p')
        },
    ]
    const [ valueForm, setValueForm ] = React.useState({
        status: 'ACTIVE',
        select: '',
        cer_nmbr: ''
    })

    const onChangeSelect = ({
        name,
        value
    }) => {
        switch(name){
            case 'status_peserta':
                setValueForm(prevState => ({
                    ...prevState,
                    status: value
                }))
                setValue('status', value)
                break
            case 'pilih_peserta':
                setValueForm(prevState => ({
                    ...prevState,
                    select: value
                }))
                setValue('select', value)
                break
        }
    }

    const downloadCard = (url) => {
        saveAs(url, "employer-ecard.png")
      }

    const onSubmit = async(data) => {
        if(!data.cer_nmbr){
            data.cer_nmbr = 0
        }
        data.cer_nmbr = parseInt(data.cer_nmbr)
        try{
            await dispatch(postHistoryEcardAction(data))
                .then(
                    res => {
                        if(res.success){
                            getCard()
                            toast.success(res.message, {
                                position: "top-right",
                                autoClose: true,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }
                )
        }catch(err){
            toast.error(err.response.data.message)
        }
    }

    const getCard = async() => {
        try{
            await dispatch(getImageCardAction())
                .then(res => {
                    if(res && res.status < 400){
                        const typeFile = res.data.type
                        switch(typeFile){
                            case 'image/png':
                                const urlCreator = window.URL || window.webkitURL;
                                const imageUrl = urlCreator.createObjectURL(res.data);
                                setECardVisible(true)
                                setEcardImgPath(imageUrl)
                                break
                            case 'application/json':
                                setECardVisible(false)
                                setEcardImgPath(null)
                                const dd = new Blob([res.data], { type: 'application/json' })
                                const fr = new FileReader()
                                fr.onload = function() {
                                    console.log(JSON.parse(this.result))
                                }
                                fr.readAsText(dd)
                                break
                        }
                    }
                })

        }catch(err){
            setECardVisible(false)
            setEcardImgPath(null)
            toast.error(err.data.message)
        }
    }

    React.useEffect(() => {
        getCard()
        register('status', { required: true })
        register('select', { required: true })
        setValue('status', 'ACTIVE')
    },[])

    React.useEffect(() => {
        if(valueForm.select === 'SEMUA_PESERTA'){
            setValue('cer_nmbr', 0)
        }
    },[valueForm])

    return (
        <Row className={clsx('py-4 mt-3')}>
            <Col md={12} className={clsx('my-3 my-md-0')}>
                <Card body>
                    <Row>
                        <Col md={12}>
                            <FormBootstrap onSubmit={handleSubmit(onSubmit)}>
                                <Row style={{ height: '100%' }}>
                                    <Col md={{ span: 5, offset: 1 }} className="d-flex flex-column mb-2 mb-md-0">
                                        <Form.Select
                                            value={valueForm.status}
                                            onChange={(e) => onChangeSelect({ name: 'status_peserta', value: e })}
                                            label={t('employerAttende.tab.filter.participant_status')}
                                            formGroupProps={{ className: clsx('w-100') }}
                                            menuProps={{ className: clsx('w-100') }}
                                            items={props.listStatusEcard}
                                            underlined
                                        />
                                        <Form.Select
                                            value={valueForm.select}
                                            onChange={(e) => onChangeSelect({ name: 'pilih_peserta', value: e })}
                                            label={t('employerAttende.tab.filter.chooseParticipant.text')}
                                            formGroupProps={{ className: clsx('w-100') }}
                                            menuProps={{ className: clsx('w-100') }}
                                            items={chooseParticipant}
                                            underlined
                                        />
                                        { valueForm.select === 'PER_PESERTA' && (
                                            <Form.Input
                                                hasGrow
                                                type="text"
                                                placeholder={t('employerAttende.tab.filter.participantNumber.placeholder')}
                                                label={t('employerAttende.tab.filter.participantNumber.label')}
                                                formGroupProps={{ className: clsx('w-100', 'mb-4') }}
                                                hasValidation
                                                ref={register}
                                                name="cer_nmbr"
                                                isInvalid={errors.attendee_id}
                                                isValid={!has(errors, 'cer_nmbr')}
                                                feedbackMessage={errors.attendee_id && t('employerAttende.tab.filter.participantNumber.error')}
                                                append={
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        placement="top"
                                                        overlay={
                                                        <Popover>
                                                            <Popover.Content>
                                                                {t('employerAttende.tab.filter.participantNumber.toolTip')}
                                                            </Popover.Content>
                                                        </Popover>
                                                        }>
                                                        <i className="mi-info"></i>
                                                    </OverlayTrigger>
                                                }
                                            />
                                        ) }
                                    </Col>
                                    <Col md={{ span: 5, offset: 1 }}>
                                        <span className="font-weight-light">{t('employerAttende.tab.filter.docType')}</span>
                                        <fieldset className={clsx('d-flex d-md-block')}>
                                            <Form.Radio
                                                checked
                                                name="doc_type"
                                                ref={register({ required: true })}
                                                value="pdf"
                                                label="PDF"
                                                formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                                            />
                                        </fieldset>
                                    </Col>
                                    <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                                        <Button
                                            loading={dataState.loading}
                                            type="submit"
                                            variant="coral"
                                            block
                                        >
                                            {t('employerAttende.tab.filter.btnProcess')}
                                        </Button>
                                    </Col>
                                </Row>
                            </FormBootstrap>
                        </Col>
                        {/* <Col md={6}>
                            <Row>
                                <Col md={11}>
                                <Card>
                                    <Card.Body className={styles.ecard__wrapper}>
                                    <Button
                                        onClick={() => downloadCard(ecardImgPath)}
                                        disabled={!eCardVisible}
                                        variant={eCardVisible ? 'outline-coral' : 'coral'}
                                        className={styles.ecard__button}>
                                        <i className={clsx('mi mi-download')}></i> Unduh
                                    </Button>
                                    <ECard imgSrc={eCardVisible ? ecardImgPath : null} />
                                    </Card.Body>
                                </Card>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default ParticipantCard
