
export const en = {
    employeeHome: {
        pageTitle: 'Good Morning ',
        box: {
            box1: {
                label: 'Your Account Balance'
            },
            box2: {
                label: 'Claim Information'
            }
        },
        statusParticipant: {
            stat1: 'Active',
            stat2: 'Non Active'
        },
        reportDownload: {
            failAlert: "Documents that can be downloaded must have the status Ready to Download"
        }
    },
}

export const id = {
    employeeHome: {
        pageTitle: 'Selamat Pagi',
        box: {
            box1: {
                label: 'Saldo Rekening Anda'
            },
            box2: {
                label: 'Informasi Klaim'
            }
        },
        statusParticipant: {
            stat1: 'Aktif',
            stat2: 'Tidak Aktif'
        },
        reportDownload: {
            failAlert: "Dokumen yang bisa di download harus berstatus Siap Diunduh"
        }
    },
}