export const en = {
    employeeInvestTitlePage: {
        text1: "Risk",
        text2: "Profile",
    },
    employeeInvestForm:{
        email:'Email Address',
        phone:'Phone Number'
    },
    employeeInvestIncomeSummation: {
        label: "Current Investment Direction",
        label2: "Current Investment Direction Group",
        fix: {
            title: "Fixed Income Fund",
            popover: "Fixed Income Fund",
        },
        shm: {
            title: "Stock Fund",
            popover: "Stock Fund",
        },
        utd: {
            title: "US Dollar Fund",
            popover: "Stock Fund",
        },
        rtd: {
            title: "Money Market Fund",
            popover: "Money Market Fund",
        },
        syh: {
            title: "Sharia Fund",
            popover: "Sharia Fund",
        },
    },
    exmployeeInvestFaq: "Related FAQs",
    employeeInvestDirectTitlePage: {
        text1: "Change ",
        text2: "Investment Direction",
    },
    employeeInvestChangeDirection: {
        tab: {
            tab1: {
                tabName: "Fill out the form",
                title: {
                    text1: "Investment Direction",
                    text2: "Change Form",
                },
            },
            tab2: {
                tabName: "OTP Verification",
                title: {
                    text1: "Verification",
                    text2: "Page",
                },
            },
            tab3: {
                tabName: "Submission Complete",
                title: {
                    text1: "Your Submission is",
                    text2: "Successful!",
                },
            },
            tab4: {
                descOtp: "Please enter OTP for validation:",
            }
        },
        buttonNext: "Next",
        buttonCancel: "Cancel",
        notFound: "Nothing found",
        formDesc:
            "For Participants whom the Employer registers, the investment option follows the provision in the applicable company regulation. Referring to the Pension Fund Policy (PDP) and prevailing laws and regulations, DPLK Manulife Indonesia is required to manage the assets of Participants under the life cycle group so that asset management for Participants who have reached the age of 5 (five) years and at the earliest 2 (two) years before the Normal Pension Age is placed on the GRO Money Market Fund and/or GRO Syariah Fund.",
        cangeInvest: "I want to change my investment direction:",
        optionCangeRequired: "You have to change options",
        instruction:
            "The new investment directions that I chose are:",
        presentace:
            "Please fill in the percentage of investment options or combination thereof, where the total investment percentage must be 100%.",
        totalPresentace: "*Total percentage has not reached 100%",
        totalPresentaceMax: "*Total sum percentage exceeds 100%",
        amountPresentace: "Total Percentage",
        investRef: "Current Investment Direction",
        investRefGroup: "Current Investment Direction Group",
        accept: "You must agree to the policy",
        acceptDesc:`I hereby certify that I understand the risks of the selected investment package in this form and that I am responsible for the risks of the selected investment package.
        I will take full responsibility for the accuracy of the information provided in this form and I agree to obey the Pension Fund regulations applied.`,
        metodeConfirm:
            "Choose one of the methods below to get a verification code",
        investChanged:
            "Congratulation! Your investment direction change application was successful. Your investment direction will change automatically no later than 2x24 hours.",
        inputOtp: "Please enter the verification code (OTP) you received ",
        otpExp: "OTP code will expire in",
        notReceiveOtp: "Not receiving OTP code?",
        resend: "Resending",
        otherNumber: "Go to the previous page?",
        changeData: "Go Back",
        noContent: "empty content",
        updateMonth: "Latest Monthly Market Review",
        desc: "",
        investName: {
            UTD: "USD Money Market",
            descUTD: "Risk Level: Medium",
            RTD: "IDR Money Market",
            descRTD: "Risk Level: Low",
            FIX: "Fixed Income",
            descFIX: "Risk Level: Medium",
            SHM: "Equity",
            descSHM: "Risk Level: High",
            SYH: "Syariah",
            descSYH: "Risk Level: Low",
        },
        nullChanges: "You have not changed your investment direction, your investment direction is still the same as the previous investment."
    },
    employeeInvestValidation: {
        desc: "Your participant status is currently inactive, and as a result, you are unable to make changes to your investment direction."
      },
    employeeInvestPrices: {
        titlePage: {
            text1: "Unit",
            text2: "Price",
        },
        filter: {
            buttonText: "Show",
            form: {
                from: "From",
                to: "To",
                alert: "The mutation period that can be selected is maximum 1 year",
                buttonRepeat: "Reset",
                formPlaceholder: "Choose Date",
            },
            desc: "",
            filterTable: {
                perfm1Year: "Performance (1 Year)",
                perfm6Month: "Performance (6 Month)",
                perfm3Month: "Performance (3 Month)",
                perfm1Month: "Performance (1 Month)",
            },
        },
        table: {
            label: "Unit Price",
            colName: {
                name: "Fund Name",
                currency: "Currency",
                date: "Date",
                price: "Unit Price",
            },
        },
        emptyPrice:
            "There is no unit price for you yet. Please check again later.",
    },
    employeeInvestConversion: {
        titlePage: {
            text1: "Exchange",
            text2: "Rate",
        },
        filter: {
            buttonText: "Show",
            form: {
                from: "From",
                to: "To",
                alert: "The mutation period that can be selected is maximum 1 year",
                buttonRepeat: "Reset",
                formPlaceholder: "Choose Date",
            },
            desc: "",
        },
        table: {
            label: "Rupiah to US Dollar Exchange Rate",
            colName: {
                date: "Date",
                buy: "Buy",
                sell: "Sell",
            },
        },
        emptyDplk:
            "Data is not available yet",
    },
    employeeFundFactSheet: {
        title: "Fund Fact Sheet",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect",
    },
    employeeMontlyMarketReview: {
        title: "Monthly Market Review",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect",
    },
    employeeUpr: {
        title: "Unit Price",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect",
    }
};

export const id = {
    employeeInvestTitlePage: {
        text1: "Profil",
        text2: "Risiko",
    },
    employeeInvestForm:{
        email:'Alamat Email',
        phone:'Nomor Ponsel'
    },
    employeeInvestIncomeSummation: {
        label: "Arahan Investasi Saat Ini",
        label2: "Arahan Investasi Group Saat Ini",
        fix: {
            title: "Dana Penghasilan Tetap",
            popover: "Dana Penghasilan Tetap",
        },
        shm: {
            title: "Dana Saham",
            popover: "Dana Saham",
        },
        utd: {
            title: "Dana US Dollar",
            popover: "Dana US Dollar",
        },
        rtd: {
            title: "Dana Pasar Uang",
            popover: "Dana Pasar Uang",
        },
        syh: {
            title: "Dana Syariah",
            popover: "Dana Syariah",
        },
    },
    exmployeeInvestFaq: "FAQ Terkait",
    employeeInvestDirectTitlePage: {
        text1: "Ubah ",
        text2: "Arahan Investasi",
    },
    employeeInvestChangeDirection: {
        tab: {
            tab1: {
                tabName: "Isi Formulir",
                title: {
                    text1: "Formulir Perubahan",
                    text2: "Arahan Investasi",
                },
            },
            tab2: {
                tabName: "Validasi OTP",
                title: {
                    text1: "Halaman",
                    text2: "Verifikasi",
                },
            },
            tab3: {
                tabName: "Pengajuan Selesai",
                title: {
                    text1: "Pengajuan anda",
                    text2: "Berhasil!",
                },
            },
            tab4: {
                descOtp: "Silakan masukkan OTP untuk validasi:",
            }
        },
        buttonNext: "Lanjut",
        buttonCancel: "Batal",
        notFound: "Tidak ada yang ditemukan",
        formDesc:
            "Untuk Peserta yang disertakan oleh Pemberi Kerja, maka arahan investasi mengikuti ketentuan Perusahaan yang berlaku. " +
            "Untuk anda yang telah mencapai usia paling lama 5 (lima) tahun dan paling cepat 2 (dua) tahun sebelum Usia Pensiun Normal, maka sesuai ketentuan peraturan perundang-undangan yang berlaku, DPLK diwajibkan untuk mengelola aset (seluruh dana yang telah terhimpun maupun iuran masuk yang akan datang) Anda sesuai dengan kelompok usia (life cycle fund) dan harus ditempatkan pada GRO Dana Pasar Uang atau GRO Dana Syariah. ",
        cangeInvest: "Saya ingin mengubah arahan investasi saya:",
        optionCangeRequired: "Anda harus pilihan pengubahan",
        instruction:
            "Arahan investasi baru yang saya pilih adalah:",
        presentace:
            "Silakan isi persentase pilihan investasi atau kombinasinya, di mana total persentase investasi harus 100%.",
        totalPresentace: "*Total jumlah persentase belum mencapai 100%",
        totalPresentaceMax: "*Total jumlah persentase melebihi 100%",
        amountPresentace: "Total Persentase",
        investRef: "Arahan Investasi Saat Ini",
        investRefGroup: "Arahan Investasi Group Saat Ini",
        accept: "Anda harus menyetujui kebijakan",
        acceptDesc: `Dengan ini saya menyatakan bahwa saya memahami risiko dari paket investasi yang dipilih dalam formulir ini dan bahwa saya bertanggung jawab atas risiko dari paket investasi yang dipilih.
        Saya akan bertanggung jawab penuh atas kebenaran informasi yang diberikan dalam formulir ini dan saya setuju untuk mematuhi Peraturan Dana Pensiun yang berlaku.`,
        metodeConfirm:
            "Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi",
        investChanged:
            "Selamat! Pengajuan perubahan arahan investasi Anda berhasil. Arahan investasi Anda akan berubah secara otomatis paling lambat 2x24 jam.",
        inputOtp: "Silakan masukkan kode verifikasi (OTP) yang Anda terima ",
        otpExp: "Kode OTP akan kadaluarsa dalam ",
        notReceiveOtp: "Tidak menerima kode OTP?",
        resend: "Kirim Ulang",
        otherNumber: "Menuju ke halaman sebelumnya?",
        changeData: "Kembali",
        noContent: "Konten kosong",
        updateMonth: "Ulasan Pasar Bulanan Terbaru",
        desc: "",
        investName: {
            UTD: "USD Money Market",
            descUTD: "Tingkat Risiko: Menengah",
            RTD: "IDR Money Market",
            descRTD: "Tingkat Risiko: Rendah",
            FIX: "Fixed Income",
            descFIX: "Tingkat Risiko: Menengah",
            SHM: "Equity",
            descSHM: "Tingkat Risiko: Tinggi",
            SYH: "Syariah",
            descSYH: "Tingkat Risiko: Rendah",
        },
        nullChanges: "Anda belum merubah arahan investasi, arahan investasi anda masih sama dengan investasi sebelumnya."
    },
    employeeInvestPrices: {
        titlePage: {
            text1: "Harga",
            text2: "Unit",
        },
        filter: {
            buttonText: "Tampilkan",
            form: {
                from: "Dari Tanggal:",
                to: "Sampai Tanggal:",
                alert: "Periode mutasi yang dapat dipilih maksimal 1 tahun",
                buttonRepeat: "Ulangi",
                formPlaceholder: "Pilih Tanggal",
            },
            desc: "",
            filterTable: {
                perfm1Year: "Performa (1 Tahun)",
                perfm6Month: "Performa (6 Bulan)",
                perfm3Month: "Performa (3 Bulan)",
                perfm1Month: "Performa (1 Bulan)",
            },
        },
        table: {
            label: "Harga Unit",
            colName: {
                name: "Nama Dana",
                currency: "Mata Uang",
                date: "Tanggal",
                price: "Harga Unit",
            },
        },
        emptyPrice:
            "Nilai Tukar belum tersedia",
    },
    employeeInvestValidation: {
        desc: "Saat ini status kepesertaan Anda tidak aktif dan tidak dapat melakukan perubahan arahan investasi."
        // desc: "Mohon Maaf anda tidak diperbolehkan untuk merubah arahan investasi, arahan investasi anda diatur oleh Pemberi Kerja."
    },
    employeeInvestConversion: {
        titlePage: {
            text1: "Nilai",
            text2: "Tukar",
        },
        filter: {
            buttonText: "Tampilkan",
            form: {
                from: "Dari Tanggal:",
                to: "Sampai Tanggal:",
                alert: "Periode mutasi yang dapat dipilih maksimal 1 tahun",
                buttonRepeat: "Ulangi",
                formPlaceholder: "Pilih Tanggal",
            },
            desc: "",
        },
        table: {
            label: "Nilai Tukar Rupiah terhadap Dolar AS",
            colName: {
                date: "Tanggal",
                buy: "Beli",
                sell: "Jual",
            },
        },
        emptyDplk:
            "Data belum tersedia",
    },
    employeeFundFactSheet: {
        title: "Fund Fact Sheet",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan",
    },
    employeeMontlyMarketReview: {
        title: "Monthly Market Review",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan",
    },
    employeeUpr: {
        title: "Harga Unit",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan",
    }
};
