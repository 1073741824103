
export const en = {
  genLang: {
    changeAccount: 'Select Accounts',
    statusParticipant: 'Participant Status: ',
    ballanceLabel: 'Your Account Balance',
    ballancePer: ' per',
    ballance: {
      tBalance: {
        desc: 'The correct data refers to the data listed on our system'
      },
      income: {
        label: 'Accumulated Contribution/Premium',
        desc: 'Total contributions/premiums that employers and/or participants have paid in a certain period'
      },
      widraw: {
        label: 'Accumulated Withdrawn Funds',
        desc: 'A portion of funds withdrawn from available balances in a certain period (Fund Withdrawal / Correction)'
      },
      development: {
        label: 'Earning',
        desc: 'The return earned on the investment of funds available in the balance within a certain period'
      },
      cost: {
        label: 'Fee',
        desc: 'Fees charged for administrative and investment services in a certain period'
      },
      tabDetailTransLabel: 'Detail Transactions',
      tabDetailInvestLabel: 'Detail Investments',
      tabDetailTransDesc: 'This feature helps you to find out and check all transaction details related to your pension fund within a maximum period of 6 (six) months according to your wishes.',
      maxPeriod: 'Maximum period of 6 (six) months',
      tableNull: 'There is no balance for you yet. Please check again later.',
      pasLabel: 'Get Financial Statements with details of accumulated balances and transactions',
      downloadNow: 'Download Now',
      pas: 'Personal Financial Statement',
      pasDesc: 'A Personal Financial Statement is a report that contains accumulated balances and transactions that occur within a certain period. For more information, please contact Manulife Indonesia Customer Service.',
    },
    pasPeriode : {
      one: 'Monthly',
      three: 'Quarterly',
      six: 'Semi Annually',
      twelve: 'Annually',
    },
    totalClaimLabel: 'Processed Claim(s)',
    downloadDocumentLabel: 'List of Documents',
    newsLabel: 'Recent News',
    greeting: {
      morning: 'Good Morning,',
      afternoon: 'Good Afternoon,',
      evening: 'Good Evening,',
      night: 'Good Night,'
    },
    logout: 'Logout',
    download: 'Download',
    delete: 'Delete',
    from: 'From',
    to: 'To',
    view: 'View',
    chooseDate: 'Choose date',
    period: 'Period',
    cancel: 'Cancel',
    year: 'Year',
    choose: 'Choose',
    request: 'Request',
    back:'Back'
  }
}

export const id = {
  genLang: {
    changeAccount: 'Pilih Akun',
    statusParticipant: 'Status Kepesertaan: ',
    ballanceLabel: 'Saldo Rekening Anda',
    ballancePer: 'Saldo per tanggal',
    ballance: {
      tBalance: {
        desc: 'Data yang benar adalah data yang tersedia pada sistem kami'
      },
      income: {
        label: 'Akumulasi Kontribusi/Premi',
        desc: 'Total kontribusi/premi/top up/Past Service Liability (tunggakan/kewajiban masa lampau) yang telah dibayar oleh pemberi kerja dan/atau peserta dalam satu periode tertentu'
      },
      widraw: {
        label: 'Akumulasi Penarikan Dana',
        desc: 'Sebagian dana yang dicairkan dari saldo yang tersedia dalam satu periode tertentu (Penarikan Dana / Koreksi)'
      },
      development: {
        label: 'Hasil Pengembangan (Net)',
        desc: 'Nominal yang ditampilkan adalah hasil pengembangan setelah dikurangi dengan biaya. Detail biaya dapat diakses melalui Rincian Transaksi yang tersedia pada kolom dibawah ini'
      },
      cost: {
        label: 'Biaya',
        desc: 'Biaya yang dikenakan atas layanan administrasi dan investasi dalam satu periode tertentu'
      },
      tabDetailTransLabel: 'Rincian Transaksi',
      tabDetailInvestLabel: 'Rincian Investasi',
      tabDetailTransDesc: 'Fitur ini membantu Anda untuk mengetahui dan memeriksa seluruh rinciann transaksi yang berkaitan dengan dana pensiun Anda dalam kurun waktu maksimal 6 (enam) bulan sesuai keinginan Anda.',
      maxPeriod: 'Maksimal periode 6 (enam) bulan',
      tableNull: 'Belum ada saldo untuk Anda. Silahkan periksa lagi nanti.',
      pasLabel: 'Dapatkan Laporan Keuangan berisi rincian akumulasi saldo dan transaksi',
      downloadNow: 'Unduh Sekarang',
      pas: 'Laporan Keuangan Pribadi',
      pasDesc: 'Laporan Keuangan Pribadi adalah laporan yang berisi akumulasi saldo dan transaksi yang terjadi dalam periode tertentu. Anda dapat meminta Laporan Keuangan Pribadi hingga kurun waktu 2 tahun ke belakang. Untuk informasi lebih lanjut, harap hubungi Customer Service Manulife Indonesia.',
    },
    pasPeriode : {
      one: 'Bulanan',
      three: 'Triwulanan',
      six: 'Semesteran',
      twelve: 'Tahunan',
    },
    totalClaimLabel: 'Jumlah Klaim diproses',
    downloadDocumentLabel: 'Daftar Dokumen',
    newsLabel: 'Berita Terkini',
    greeting: {
      morning: 'Selamat Pagi,',
      afternoon: 'Selamat Siang,',
      evening: 'Selamat Sore,',
      night: 'Selamat Malam,'
    },
    logout: 'Keluar',
    download: 'Unduh',
    delete: 'Hapus',
    from: 'Dari Tanggal:',
    to: 'Hingga',
    view: 'Tampilkan',
    chooseDate: 'Pilih tanggal',
    period: 'Periode',
    cancel: 'Batal',
    year: 'Tahun',
    choose: 'Pilih',
    request: 'Minta',
    back:'Kembali'
  }
}
